import React, { useEffect, useState } from "react";
import './PackageDetails.css'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { HiOutlineIdentification } from "react-icons/hi2";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useParams } from "react-router-dom";
import PopupForm from "./PopupForm/PopupForm";
import APIPath from "../../../config";

const PackageDetails = () => {
    const { id} = useParams();
    const [packageDetails, setPackageDetails] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [isScrolled, setIsScrolled] = useState(true);
    const [blur,setBlure]=useState(false);
    const [loading, setLoading] = useState(true);


    const handleQueryButtonClick = () => {
      setShowForm(true);
      setBlure(true)
      document.body.style.overflow = 'hidden';
    };
  
    const handleCloseForm = () => {
      setShowForm(false);
      setBlure(false)
      document.body.style.overflow = 'auto';
    };
   
    useEffect(() => {
        fetch(`${APIPath}/api/v1/packages/?id=${id}`)
            .then(response => response.json())
            .then(result => {
                 console.log(result.data)
                 setPackageDetails(result.data); 
                 setLoading(false);
            })
            .catch(error => console.log('error', error))          
    }, [id]);

    useEffect(() => {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        setIsScrolled(scrollY < 200);
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);  

    const packageDetailsData = packageDetails.length > 0 ? packageDetails[0] : [];

    const PackageName=packageDetailsData.title;

    let imageUrl=packageDetailsData.bannerImage;
    
    const price = packageDetailsData?.price && packageDetailsData.price.length > 0
                  ? packageDetailsData.price[0].price : null;

    return (
        <> 
        {loading ? <div className="loader-container">
        <div className="loader"></div>
          </div> :
        <div className={blur ? "packege-details" : "package"}>
        <div className="divStyled">
            <img src={imageUrl} alt="bannerImage"/>
        </div>
        
        <div className="image-footer">
           <div className="image-footer-logo-duration">
            <p><AccessTimeIcon style={{fontSize:"1.3rem"}}/>&nbsp; </p>
            <p className="image-footer-duration">{packageDetailsData.duration}</p>
           </div>
           <div className="image-footer-logo-duration">
            <p><CalendarTodayIcon style={{fontSize:"1.2rem"}}/>&nbsp; </p>
            <p className="image-footer-duration">Peak Time Nov - Mar</p>
           </div>
            <p><b> AED </b>{price} per person</p>
            <p> <HiOutlineIdentification style={{fontSize:"1.5rem"}} /> 7days Visa Processing</p>

        </div>
        <div className="packageDetails-container">
            <h2>{packageDetailsData.title}</h2>
            <p>{packageDetailsData.packageOverview}</p>
            <br/>
            <h3>Routing</h3>
            <p>Trip Duration:{packageDetailsData.duration}</p> 
            <br/>
            <p> {packageDetailsData?.duration?.split("/")[0]}&nbsp;Dubai</p>
            <br/>
            <div className="inclusion"> 
            <h3>Inclusions:</h3>
              {packageDetailsData.includedServices?.map((list,id)=>{
                     return<>
                      <li key={id}>{list}</li>
                     </>
              })}
            </div>
            <br/>
            <div className="exclusion">
                <h3>Exclusions:</h3>
                {packageDetailsData.excludedServices?.map((list,id)=>{
                     return<>
                      <li key={id}>{list}</li>
                     </>
              })}
            </div>
            <br/>
            <div className="day-wise-plan">
              <h3>Day Wise Itinerary</h3>
              <br/>
              {packageDetailsData.dayWisePlan?.map((list,id)=>{
                     return<>
                      <h4>{list.title}</h4>
                      <p key={id}>{list.description}</p> <br/>
                     </>
              })}
            </div>
            <br/>
            {/* ------------------------Hotel Image------------------- */}
            <div className="hotel-packages">
              <h3>Hotel Details </h3>
              {packageDetailsData.hotel.length > 0 ? packageDetailsData.hotel?.map((val,id)=>{
                return <>
                  <div className="hotel-card"> 
                      <div className="hotel-image">
                         <img src={val.images[0]} alt="Hotel"/>
                      </div>
                      <div className="hotel-details">
                            <h4>{val.name}</h4>
                            <h5> Rating:&nbsp;{val.rating.average}</h5>
                            <p>{val.hotelDescription}</p>
                            <a href={val.contact.website} target="blank">Hotel Website</a>
                      </div>
                  </div>
                </>
              }):(<div style={{textAlign:"center"}}>
                <h5>We Are working on It.</h5>
              </div>)}
            </div>
            <br/>
            {/* --------------------------------Hotel end */}
            <div className="booking-procedure">
                <h3>Booking Procedure</h3>
                <ul>
                {packageDetailsData.bookingProcedure?.map((list,id)=>{
                     return<>
                      <li key={id}>{list}</li>
                     </>
                })}
                </ul>
            </div>
            <br/>
            <div className="cancellation-policy">
              <h3>Cancellation Policy</h3>
              {packageDetailsData.cancellationRefundPolicy?.map((list,id)=>{
                     return<>
                      <p key={id}>{list}</p>
                     </>
              })}
            </div> <br/>
            {/* <div className="notes">
              <h3>Please Note:</h3>
                  <ul>
                      {packageDetailsData.policyNote?.map((list,id)=>{
                        return<>
                          <li key={id}>{list}</li>
                        </>
                      })} 
                  </ul>
                
            </div> */}
            <br/>
            <div className="must-carry">
              <h3>Must Carry</h3>
              <ol>
                {packageDetailsData.mustCarry?.map((list,id)=>{
                     return<>
                      <li key={id}>{list}</li>
                     </>
                })}
              </ol>
              
            </div>
        </div>
        <div className={`footer ${isScrolled ? 'footer-hidden' : ''}`}>
           <div className="footer-price">
              <p>Starting at: <span><b> AED {price}</b> per person</span></p>
           </div>
           <div className="footer-more-btn">
                 <p className="footer-more-desktop"> To plan your next tour with US!! click on &nbsp;</p>
                 <p className="footer-more-tab-mob"> For More! click here &nbsp;</p>
                 <p> <button onClick={handleQueryButtonClick}>Send a query <ArrowOutwardIcon fontSize="1.5rem" /></button></p>
            </div> 
        </div>   
           {showForm && <PopupForm onClose={handleCloseForm} packageId={id} packageName={PackageName} />}
           </div>
        }  
        </>
    );
}

export default PackageDetails;
