import React, { useEffect, useState } from "react";
import './LandCombosDetails.css';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PopupForm from "../PackageDetails/PopupForm/PopupForm";
import { useParams } from "react-router-dom";
import APIPath from "../../../config";

const LandCombosDetails = () => {
  const { id } = useParams()
  const [landcombosDetails, setLandCombosDetails] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [blur, setBlure] = useState(false)
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(true);

  useEffect(() => {
    setLoading(false);
    fetch(`${APIPath}/api/v1/land_combos?id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        setLandCombosDetails(data.data)
      })
      .catch((err) => {
        alert(err)
      })
  }, [id])

  const PackageName = landcombosDetails[0]?.title;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY < 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleQueryButtonClick = () => {
    setShowForm(true);
    setBlure(!blur);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setBlure(false);
    document.body.style.overflow = 'auto';
  };

  return <>
    {loading ? <div className="loader-container">
      <div className="loader"></div>
    </div> :
      <div className={blur ? "packege-details" : "package"}>
        <div className="landcombos-banner">
          <img src={landcombosDetails[0]?.bannerImage} alt="Banner" />
        </div>
        <div className="landcombos-details">
          <h2>{landcombosDetails[0]?.title}</h2>
          <div>
            <h5>{landcombosDetails[0]?.landComboOverview}</h5>
            {/* {landcombosDetails[0]?._id === "65923d9953a40ea980439d7f" && (
                <h5>Future Museum + Miracle Garden + Ticket OF Ski Dubai + Burj Khalifa + Musical Fountain Show + Return drop to hotel.</h5>
              )} */}
          </div>
          <br />
          {/* <h4> <b>Cost: {landcombosDetails[0]?.cost}</b> </h4> */}
          {/* <br/> */}
          {/* ------------------------------- Inclusion */}
          <div className="inclusion">
            <h4> Inclusions </h4>
            <ul>
              {landcombosDetails[0]?.includedServices.map((val, id) => {
                return <>
                  <li key={id}>{val}</li>
                </>
              })}
            </ul>
          </div><br />
          {/* -------------------------------- Exclusion */}
          <div className="exclusion">
            <h4> Exclusions </h4>
            <ul>
              {landcombosDetails[0]?.excludedServices.map((val, id) => {
                return <>
                  <li key={id}>{val}</li>
                </>
              })}
            </ul>
          </div>
          <br />
          {/* <div>
             {landcombosDetails[0]?._id === "65923d6353a40ea980439d7d" && (
                <h5>Pick Up from Abudhabi airport to hotel in Yas Island.</h5>
              )}
             </div> */}
          {landcombosDetails[0]?.expectations &&
            <div>
              <h2>Meeting Point</h2>
              {landcombosDetails[0].expectations.map((val, id) => {
                return <div id="landcombos-location">
                  <p><b>Location:</b> {val.location}</p>
                  <p><b>Description:</b> {val.description}</p>
                </div>
              })}
            </div>
          }
          <br />
          {landcombosDetails[0]?.expectations &&
            <div>
              <h2>Cancellation Policy</h2>
              {landcombosDetails[0].cancellationRefundPolicy.map((val, id) => {
                return <ul>
                  <li>{val}</li>
                </ul>
              })}
            </div>
          }
          <br/>
          <h5> <b>Note: </b>{landcombosDetails[0]?.note}</h5>
        </div>
        {/* ----------------------------- Footer  */}
        <div className={`footer ${isScrolled ? 'footer-hidden' : ''}`}>
          <div>
            <p>Starting at: <span><b> AED {Math.abs(landcombosDetails[0]?.price?.[0].adultPrice)}</b> per person</span>
            </p>
          </div>
          <div>
            <div>
              <p> To plan your next tour with US!! click on &nbsp;
                <button onClick={handleQueryButtonClick}>Send a query <ArrowOutwardIcon fontSize="1.5rem" /></button>
              </p>
            </div>
          </div>
        </div>
        {showForm && <PopupForm onClose={handleCloseForm} packageId={id} packageName={PackageName} />}
      </div>
    }
  </>
}
export default LandCombosDetails;
