import { useEffect, useState } from "react";
import Rating from '@mui/material/Rating';
import SearchIcon from '@mui/icons-material/Search';
import './CategoryDetails.css'
import { useParams } from "react-router-dom";
import { useNavigate,useLocation } from "react-router-dom";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LanguageIcon from '@mui/icons-material/Language';
import Footer from "../../Footer/Footer";
import APIPath from "../../../config";

const CategoryDetails=()=>{
    const {name} = useParams();
    const { state } = useLocation();
    const id = state?.id;
    const[packagebycategory,setPackagebycategory]=useState([])
    useEffect(() => {
        fetch(`${APIPath}/api/v1/packages?categoryId=${id}`)
            .then(response => response.json())
            .then(result => { 
                setPackagebycategory(result.data);
            })
            .catch(error => console.log('error', error));
    }, []);

    // console.log(packagebycategory[0]?.categoryName)

    const navigate = useNavigate();
    const handleCardId=(id)=>{
        // setClickCardId(id)
        navigate(`/packages/${id}`);
    }
    return <>
    {/* ------------------------------------------- Top Banner */}
    <div className='package-banner'>
        <div className="main-container">
             <br/>
            <h2 style={{marginBottom:"1rem"}}> Land Combos for you! </h2>
            <div id="search-container">
                <form className='video-form'>
                    <input className="search-input" type="text" placeholder="Search..." />
                    <button  className="search-button"> <SearchIcon/> </button>
                </form>
            </div>
            <p className="search-packages-combos-attractions">
                <a href='/package' className="check-button">View Packages &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href='/landcombos' className="check-button">Land Combos &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href='/attractions' className="check-button">Attractions &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a>
            </p>
        </div>
      </div>
    {/* ------------------------------------------------ */}
       <div className="category-details-container">
         <h2> {packagebycategory[0]?.categoryName}</h2>
        <div className="category-details-card-parent">
            {packagebycategory.length > 0 ? packagebycategory.map((val,id)=>{
                return <>
                  <div className="category-details-card" onClick={()=>handleCardId(val._id)}>
                    <div className="category-details-image">
                        <img src={val.thumbnailImage} alt={val.title} />
                    </div>
                    <div className="category-details" 
                    
                    >
                        <h4>{val.title}</h4>
                        <div className="super-sell-rating-icon">
                            <p>Ratings &nbsp;</p>
                            <p> <Rating defaultValue={val.ratings} precision={0.5} readOnly  /></p>
                        </div>
                        {/* <p>{val.duration}</p> */}
                                 <p style={{padding:"0.5rem 0"}}>
                                     <span><AccessTimeIcon style={{fontSize:"1rem"}}/> 3 Hours</span> &nbsp;
                                     <span><DirectionsCarIcon style={{fontSize:"1rem"}}/> Pickup offered </span> &nbsp;  
                                     <span><LanguageIcon style={{fontSize:"1rem"}}/> English</span> &nbsp;
                                 </p>
                        <div className="supercell-price-arrow">
                                    <p>
                                        price per person: <b>AED {val.price[0].price}</b>
                                    </p>
                                    <p>
                                        <ArrowOutwardIcon />
                                    </p>
                        </div>
                    </div>
                  </div>

                </>
            }) :<h6 style={{padding:"1rem 4rem 4rem 4rem",fontSize:"2rem",margin:"auto"}}> No package available for Now!</h6>}
        </div>
       </div>
       <Footer />
    </>
}
export default CategoryDetails;