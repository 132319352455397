import React,{useState,useEffect} from "react";
import SearchIcon from '@mui/icons-material/Search';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import './PackageNav.css'
import Footer from "../../Footer/Footer";
import ThingsIndubai from "../ThingsInDubai/ThingsIndubai";
import Rating from '@mui/material/Rating';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LanguageIcon from '@mui/icons-material/Language';
import { Link, useNavigate } from "react-router-dom";
import APIPath from "../../../config";

const PackageNav=()=>{
    const [topPackage, setTopPackage] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [originalPackages, setOriginalPackages] = useState([]);
    const [searchTerm,setSearchTerm]=useState('');

    useEffect(() => {
        fetch(`${APIPath}/api/v1/packages`)
            .then(response => response.json())
            .then(result => {
                setTopPackage(result.data);
                setOriginalPackages(result.data)
                setLoading(false)
            })
            .catch(error => console.log('error', error));
    }, []);

    // const topFivePackages = topPackage.slice(startIndex, startIndex + 5);

    const navigate = useNavigate();
    const handleCardId = (id) => {
        navigate(`/packages/${id}`);
    }
    
    const handleSearch=()=>{
        const searchTermLower=searchTerm.toLowerCase();
        if (searchTermLower === "") {
            setTopPackage(originalPackages);
        }
        else {
          const filteredPackages = originalPackages.filter((packages) =>
            packages.title.toLowerCase().includes(searchTermLower)
          );
          setTopPackage(filteredPackages);
        }
      }

    return <>
       <div className='package-banner'>
        <div className="main-container">
            <h2 style={{marginBottom:"1rem"}}> Packages created for you </h2>
            <div id="search-container">
                <form className='video-form' onSubmit={(e)=>{e.preventDefault(); handleSearch();}}>
                    <input
                    className="search-input"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button  className="search-button"> <SearchIcon/> </button>
                </form>
            </div>
            <p className="search-packages-combos-attractions">
                <Link to="/packages" className="check-button">View Packages &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' /></Link>
                {/* <a href="/packages" className="check-button">View Packages &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a> */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/landcombos" className="check-button">Land Combos &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' /></Link>
                {/* <a href="/landcombos" className="check-button">Land Combos &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a> */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/attractions" className="check-button">Attractions &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' /></Link>
                {/* <a href="/attractions" className="check-button">Attractions &nbsp;&nbsp;
                <ArrowOutwardIcon fontSize='1.5rem' />
                </a> */}
            </p>
        </div>
      </div>
      <div className="packages-list">
         {loading ? <div className="loader-container">
                <div className="loader"></div>
                </div> :
            <div className="top-package-container">
                <div className="top-package-crousel">
                    <h2>Top Packages for Dubai</h2>
                </div>
                
                 <div className="top-package-card-container1">
                      {topPackage.map((val, id) => (
                         <div key={id} className="top-package-card" onClick={() => handleCardId(val._id)}>
                             <div className="top-package-card-image">
                               <img src={val.thumbnailImage} alt={val.title} />
                             </div>
                            
                            <div className="top-package-card-details" >
                                <br/>
                                 <h4>{val.title}</h4>
                                 <div className="rating-and-ratingicon">
                                    <div className="ratings">
                                        <p>Ratings &nbsp; </p>
                                    </div>
                                    <div>
                                        <p >
                                        <Rating style={{fontSize:"1.2rem",overflow:"hidden"}} defaultValue={val.ratings} precision={0.5}  readOnly  />
                                        </p>
                                    </div>

                                  </div>
                                 <p>
                                     <AccessTimeIcon style={{fontSize:"1rem"}}/><span style={{fontSize:"0.9rem"}}> 3 Hrs</span> &nbsp;
                                     <DirectionsCarIcon style={{fontSize:"1rem"}}/> <span style={{fontSize:"0.9rem"}}>Pickup offered </span> &nbsp;  
                                     <LanguageIcon style={{fontSize:"1rem"}}/><span style={{fontSize:"0.9rem"}}> Eng</span> &nbsp;
                                 </p>
                                 <div className="price-arrow">
                                    <p>Price per person:
                                      <b> AED &nbsp;<strong>{val.price[0].price}</strong> </b>
                                    </p>
                                     <p><ArrowOutwardIcon /></p>
                                 </div>
                                 
                             </div>
                           
                         </div>
                     ))} 
                 </div>
             </div> 
        }   
      </div>
      <br/>
      <br/>
      <ThingsIndubai />
      <br/>
      <br/>
      <Footer/>
    </>
}

export default PackageNav;